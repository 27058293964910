import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('module/finance_paylater', {
            params: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/finance_paylater/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('module/finance_paylater', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`module/finance_paylater/${userData.id}`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/module/finance_paylater/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/finance_paylater/download/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`finance_paylater/filedocument/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletefileData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/finance_paylater/filedocument/+${id}')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchefamilymemberdetails(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/familymembers/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchepaylaters(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/paylaterwallets/getdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


  },
}
